<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
        { validator: lengthValidator, trigger: 'blur' },
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>

      <el-input
        v-if="!richEditor"
        :value="value"
        type="textarea"
        :autosize="{minRows: minRows || 2, maxRows: minRows || 2}"
        :name="name"
        :size="size"
        :disabled="readonly"
        :placeholder="placeholder || $locale.main.placeholder.string_field"
        @input="$emit('input', $event)"
      ></el-input>
      <ckeditor
        v-else
        ref="editor"
        v-model="localModel"
        :editor="editor"
        :disabled="readonly"
        style="border-radius: 4px; border: 1px solid rgb(220, 223, 230)"
        :style="'height:' + height + ';width:' + width"
        @ready="onReady"
      ></ckeditor>
      <span v-if="isShowLengthTextarea" class="count">{{lengthString}}/{{showlength}}</span>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from './registry_mixins'
import InputLabel from '@/mixins/inputLabel.js'

import CKEditor from '@ckeditor/ckeditor5-vue2'
import InlineEditor from '@/components/HTMLEditor/ckeditor.js'
import CKEditorMixin from '@/components/InterfaceEditor/components/CKEditorMixin'

export default {
  components: {
    ckeditor: CKEditor.component
  },
  name: 'text_field',
  mixins: [mixin, registryMixin, InputLabel, CKEditorMixin],
  props: {
    label: {
      description: 'Название',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    minRows: {
      description: 'Строк в высоту',
      type: String,
      default: '2'
    },
    richEditor: {
      description: 'Ричедитор',
      type: Boolean
    },
    height: {
      description: 'Высота ричедитора',
      type: String,
      options: {
        tooltip: {
          show: true,
          content: 'Применяется с единицами измерениями px, %, em и тд. Пример: 100px'
        }
      }
    },
    minlength: {
      description: 'Минимальное кол-во символов',
      type: String,
      default: null
    },
    maxlength: {
      description: 'Максимальное кол-во символов',
      type: String,
      default: null
    }
  },
  watch: {
    localModel (value) {
      this.$emit('input', value)
    }
  },
  data () {
    return {
      localModel: this.value,
      editor: InlineEditor
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    },
    showlength () {
      if (this.maxlength) {
        return this.maxlength
      }

      return this.minlength
    },
    isShowLengthTextarea () {
      if (this.maxlength || this.minlength) {
        return true
      }

      return false
    },
    lengthString () {
      if (this.value) {
        return this.value.length
      }
      return 0
    }
  },
  methods: {
    lengthValidator (rule, value, callback) {
      if (!this.isShowLengthTextarea || !value) {
        return callback()
      }

      let message = ''
      if (this.maxlength && value.length >= this.maxlength) {
        message = `Количество символов не может привышать ${this.maxlength}`
        return callback(message)
      }
      if (this.minlength && value.length < this.minlength) {
        message = `Количество символов должно быть больше ${this.minlength}`
        return callback(message)
      }

      return callback()
    }
  }
}
</script>

<style>
  .quill-editor .ql-container.ql-snow {
    height: calc(100% - 58px);
  }
  :root {
    --ck-z-default: 1500;
  }
  .el-textarea +.count {
    color: #909399;
    background: #fff;
    position: absolute;
    font-size: 12px;
    bottom: 5px;
    right: 10px;
}
</style>
